<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row no-print">
                        <h3 class="mt-1 col-12 mb-2"> Cabina </h3>
                    </div>
                    <hr class="no-print">
                    <div class="row px-1 pb-0 pt-1 mb-1 no-print">
                        
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select> 

                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">

                        <label class="col-xl-1 ms-3 mt-2 px-0"> Cabina:</label>
                        <select class="form-control col-xl-2 mx-1" v-model="cabina_id">
                            <option value="">  </option>
                            <option v-for="cabina in cabinas" :key="'c' + cabina.cabina_id" v-bind:value="cabina.cabina_id">{{cabina.cabina_name}}</option>
                        </select>

                        <label class="mt-1 col-xl-1 mb-2"> Price: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="price" :disabled="formCreated && rows.length > 0">

                        <button class="btn btn-success col-xl-1 col-md-4 mx-2 mb-2" @click="getData()"> <i class="fas fa-search"></i> </button>
                        <button class="btn btn-primary col-xl-1 col-md-4 mx-2 mb-2" v-if="!formCreated && rows.length > 0" @click="saveCabinaData()"> <i class="fas fa-plus"></i> Form </button>
                        <button class="btn btn-danger col-xl-1 col-md-4 mx-2 mb-2" v-else-if="formCreated && rows.length > 0" @click="deleteCabinaData()"> <i class="fas fa-trash"></i> Form </button>

                    </div>
                    <hr class="no-print">

                    <div class="row mt-3">
                        <div class="col-4" style="color: black;">
                            <h3> Ideal Power Company </h3>
                            <p> For Contracting Electrical, Mechanical, General Trading, Renewable Energy
                             &#38; Clean Energy / LTD
                            </p>
                        </div>
                        <div class="col-4 text-center" style="color: black;">
                            <img src="../assets/Ideal power logo.png" width="75%" class="mt-n3">
                        </div>
                        <div class="col-4 text-right" style="color: black;">
                            <h2> کۆمپانیای ئایدیەڵ پاوەر </h2>
                            <p> بۆ بەڵێندەرایەتی کارەبایی و میکانیکی و بازرگانی گشتی و وزەی نوێ بووە و وزەی تاک / سنوردار
                            </p>
                        </div>
                    </div>
                    <hr class="my-1">
                    <p class="text-center" style="color: black;">  لیستی مانەوەی کارمەندانی کۆمپانیای ئایدیەڵ پاوەر لە کابینەکان - مانگی {{ month }} / {{ year }}</p>
                    <p class="text-center" style="color: black; font-size: 14pt;" dir="rtl" v-if="cabinas.find(c => c.cabina_id == cabina_id)">  کابینەی: {{  cabinas.find(c => c.cabina_id == cabina_id).cabina_name }} </p>
                    <div>
                        <table dir="rtl" class="table table-bordered text-center" style="page-break-inside: always; font-size: 12pt !important;">
                            <thead>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 2%;"> ژ.</th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> ناو </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> ژمارە تەلەفۆن </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> ستاف </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> وڵات </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> شار </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 8.3%;"> لەدایکبوون </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> کار </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt;"> کۆدی ئاسایش </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 7%;"> بڕی پارە </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 13.75%;"> بەسەرچوونی پاسپۆرت </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 13.75%;"> ژمارەی پاسپۆرت </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 12.5%;"> بەسەرچوونی ئیقامە </th>
                                <th style="font-weight: 600; background-color: #EBEBF0 !important; font-size: 10.5pt; width: 12.5%;"> ژمارەی ئیقامە </th>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in rows" :key="row.emp_id">
                                    <td style="font-size: 10pt;"> {{ index + 1 }} </td>
                                    <td style="font-size: 10pt;"> {{ row.full_name }} </td>
                                    <td style="font-size: 10pt;"> {{ row.phone }} </td>
                                    <td style="font-size: 10pt;"> {{ row.staff_name }} </td>
                                    <td style="font-size: 10pt;"> {{ row.country | country_filter }} </td>
                                    <td style="font-size: 10pt;"> {{ row.city }} </td>
                                    <td style="font-size: 10pt;"> {{ row.birth_date ? new Date(row.birth_date).toISOString().split('T')[0] : '' }} </td>
                                    <td style="font-size: 10pt;"> {{ row.job }} </td>
                                    <td style="font-size: 10pt;"> {{ row.asaish_code }} </td>
                                    <td style="font-size: 10pt;"> {{ (row.price || 0).toLocaleString() }} </td>
                                    <td style="font-size: 10pt;"> {{ row.expiry_passport ? new Date(row.expiry_passport).toISOString().split('T')[0] : '' }} </td>
                                    <td style="font-size: 10pt;"> {{ row.passport_number }} </td>
                                    <td style="font-size: 10pt;"> {{ row.expire_accomodation ? new Date(row.expire_accomodation).toISOString().split('T')[0] : '' }} </td>
                                    <td style="font-size: 10pt;"> {{ row.accomodation_number }} </td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="para-total"> کۆی گشتی </td>
                                    <td class="para-total"> {{ (rows.reduce((a, b) => a + (b.price || 0), 0)).toLocaleString() }} </td>
                                    <td colspan="4" class="para-total">  </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <export-excel :data="excelData" v-if="user.type == 'A' || user.type == 'K'">
                            <v-btn color="success" small class="mt-2 no-print"> <v-icon class="mr-3"> mdi-microsoft-excel </v-icon> Download Excel </v-btn>
                        </export-excel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth';
export default {
    data() {
        return {
            user: {
                username: "",
                type: "",
                en_id: ""
            },
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            rows: [],
            cabinas: [],
            cabina_id: null,
            price: 0
        }
    },
    beforeCreate() {
        auth("employees");
    },
    created() {
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username
            this.user.type = data.type
        });

        this.$http.post('/cabina/getData').then(({data}) => {
            this.cabinas = data
        })
    },
    methods: {
        getData() {
            if(this.month && this.year && this.cabina_id) {
                this.$http.get('/cabina/getEmployeeCabina/' + this.month + '/' + this.year + '/' + this.cabina_id).then(({data}) => {
                    this.rows = data
                    if(this.rows.length > 0) {
                        this.price = this.rows[0].price
                    } else {
                        this.price = 0
                    }
                })
            } else {
                this.rows = []
            }
        },
        saveCabinaData() {
            this.$confirm('Are you sure you want to save this form?', '', "question").then(() => {
                this.$http.post('/cabina/saveEmployeeCabina/' + this.month + '/' + this.year + '/' + this.price + '/' + this.cabina_id).then(({data}) => {
                    this.$alert("Form Created", "Success", "success");
                    this.rows = data
                    if(this.rows.length > 0) {
                        this.price = this.rows[0].price
                    } else {
                        this.price = 0
                    }
                }).catch(() => {
                    this.$alert("Failed", "Error", "error");
                })
            })
        },
        deleteCabinaData() {
            this.$confirm('Are you sure you want to delete this form?', '', "question").then(() => {
                this.$http.delete('/cabina/deleteEmployeeCabina/' + this.month + '/' + this.year + '/' + this.cabina_id).then(({data}) => {
                    this.$alert("Form Deleted", "Success", "success");
                    this.rows = data
                    if(this.rows.length > 0) {
                        this.price = this.rows[0].price
                    } else {
                        this.price = 0
                    }
                })
            }).catch(() => {
                this.$alert("Failed", "Error", "error");
            })
        }
    },
    computed: {
        formCreated() {
            return this.rows.every(r => r.saved_cabina_id)
        },
        excelData() {
            return this.rows.map(r => {
                return {
                    "ناو": r.full_name,
                    "ژمارە تەلەفۆن": r.phone,
                    "ستاف": r.staff_name,
                    "وڵات": this.$options.filters.country_filter(r.country),
                    "شار": r.city,
                    "لەدایکبوون": r.birth_date ? new Date(r.birth_date).toISOString().split('T')[0] : '',
                    "کار": r.job,
                    "کۆدی ئاسایش": r.asaish_code,
                    "بڕی پارە": (r.price || 0),
                    "بەسەرچوونی پاسپۆرت": r.expiry_passport ? new Date(r.expiry_passport).toISOString().split('T')[0] : '',
                    "بەسەرچوونی ئیقامە": r.expire_accomodation ? new Date(r.expire_accomodation).toISOString().split('T')[0] : ''
                }
            })
        }
    },
    watch: {
        month() {
            this.getData()
        },
        year() {
            this.getData()
        },
        cabina_id() {
            this.getData()
        }
    },
    filters: {
        country_filter(value) {
            return {
                "1": "Iraq",
                "2": "Foreign",
                "3": "Iran",
                "4": "Bangladish",
                "5": "Syria",
                "6": "India",
                "7": "Pakistan",
            }[value]
        },
    }
}
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 12pt;
        color: black;
        font-weight: 500;
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
                
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>